.root {
  .content {
    max-width: 832px
  }

  .button {
    margin-top: calc(var(--spacing) * 3);
  }

  .typo {
    text-align: left;
    margin-bottom: calc(var(--spacing) * 3);
  }
}
